@import "../../../mixins";

.library_element {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  max-width: 1200px;
  width: calc(100vw - 430px);
  &.display {
    height: auto;
  }
}

.six_dot_balancer {
  padding-top: 14px;
  padding-bottom: 15px;
  transition: padding 0.3s ease-in-out;

  &.isCompressView{
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .rising_button {
    display: none;
  }
  &.balancerAfterTitle {
    padding-right: 50px;
  }
  .six_dot_wrap {
    visibility: visible;
  }
  &.hoverable:hover .six_dot_wrap .six_dot_stripe {
    display: block;
  }
  &.hoverable:hover .six_dot_icon {
    display: flex;
  }
  &.hoverable:hover .six_dot_placeholder {
    display: none;
  }
  &.hoverable:hover .six_dot_placeholder.not_hidden {
    display: flex;
  }
  &.hoverable:hover .show_analog_wrap_on_hover {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: no-drop;
    & svg {
      width: 27px;
      path {

        fill: #767676;
      }
    }
  }
}
.six_dot_balancer:hover {
  .rising_button {
    display: block;
  }
  .rising_button_flex {
    display: flex;
  }
  & .checkbox_balancer{
    opacity: 1;
  }
}
.checkbox_balancer.selected_checkbox{
  opacity: 1;
}
.checkbox_balancer.isPlaceHolder{
  visibility: hidden;
}
.collapsed_chevron_wrap {
  display: flex;
  width: 16px;
  justify-content: center;
  & .collapsed_chevron{
    z-index: 10;
    border-radius: 4px;
    position: absolute;
    top: 7px;
    width: 32px;
    height: 32px;
    left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & svg{
      transform: rotate(90deg);
    }
    &.isCollapsed svg{
        transform: rotate(0deg);
    }

    &:hover{
      filter: drop-shadow(0px 1px 4px rgba(122, 105, 105, 0.10));
      border: 0.6px solid #FFC72C;
      background: #FFEEBF;
    }
  }
}
.text_container {
  &:focus-within {
    height: 100%;
  }
  &:focus-within {
    overflow: visible;
  }
  &:focus-within .collapsed_chevron_wrap{
    visibility: hidden;
  }

  &.isCollapsed {
    height: 47px;

  }
  &.isCollapsed:focus-within {
    height: 100%;
  }
  &.isCanCollapsed {
    overflow: hidden;
    transition: height 0s ease-in-out;
  }
  &.isCanCollapsed:focus-within  {
    overflow: visible;
  }
  &:not(.isCollapsed) {
    overflow: visible;
  }
}
.text_element {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  max-width: 1200px;
  width: calc(100vw - 430px);

  //&:hover{
  //  border-color: #E6AC190F;
  //}
  //&:focus-within{
  //  background: rgba(230, 172, 25, 0.0588235294);
  //}
  &.has_indention {
    padding-left: 50px;
  }
  &.blink_it .title_indent{
    animation: blinking_touch 1s;
    animation-iteration-count: 2;
  }
  &.blink_it .rich_text{
    animation: blinking_touch 1s;
    animation-iteration-count: 2;
  }
  &:focus-within .collapsed_chevron_wrap{
    visibility: hidden;
  }

  &.hoverable:hover.notEdit.notEdit {
    cursor: default;
  }
  &.hoverable:hover .six_dot_wrap {
    visibility: visible;
  }
  &.hoverable:hover .text_container:not(.text_container:focus-within) {
    //background-color: #FFE9AD;
  }
  &.dragginOver {
    background-color: #ffe9ad;
    & .text_container {
      background-color: #ffe9ad;
    }
  }
}

.six_dot_balancer:hover {
  & .rich_text.rich_text {
      //cursor: text;
    background: linear-gradient(0deg, var(--bg-hover--channel, rgba(230, 172, 25, 0.06)) 0%, var(--bg-hover--channel, rgba(230, 172, 25, 0.06)) 100%), #FFF;

  }
  & .title_indent {
    border: 1px solid  #ECCE83;
    background: linear-gradient(0deg, var(--bg-hover--channel, rgba(230, 172, 25, 0.06)) 0%, var(--bg-hover--channel, rgba(230, 172, 25, 0.06)) 100%), #FFF;

  }
  & .highlightedPlace {
    border: 1px solid  #ECCE83;
    background: linear-gradient(0deg, var(--bg-hover--channel, rgba(230, 172, 25, 0.06)) 0%, var(--bg-hover--channel, rgba(230, 172, 25, 0.06)) 100%), #FFF;
  }
}
.rich_text.rich_text.rich_text {
  border: 1px solid var(--border-subtle, #E0DFE2);
}
.title_indent.title_indent {
  border: 1px solid var(--border-subtle, #E0DFE2);
}
.highlightedPlace.highlightedPlace {
  border: 1px solid var(--border-subtle, #E0DFE2);

}
.text_container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 48px;
  border-radius: 8px;
  //padding:4px;
  & .line_wrapper {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    position: absolute;
    height: 16px;
    bottom: 0;
    left: 0;
    & .line {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #c5ceff;
    }
  }
  border: 0.6px solid;
  border-color: rgba(255, 255, 255, 0);
  transition: all 0.3s ease-in-out;
  &.rich_text {
    position: relative;
    border-radius: 12px;
    background-color: white;

    &:focus-within {
      cursor: text;
      background: #E6AC190F;
      border-radius: 12px;
      border-color: #E6AC190F;
    }

    &:hover {
      cursor: text;
    }
    &.selected {
      border-radius: 12px;
      background: #F8EAC9;
      border-color: #F8EAC9;
    }
    &:focus {
      border-color: #E6AC190F;

      .editor-container-wrap .toolbar {
        background: #F8EAC9;
      }
    }
    .checkbox_container {
      position: absolute;
      right: 10px;
      top: 30px;
    }
    &.isAIGenerated {
      outline: 4px solid rgba(25, 230, 39, 0.24);
      border-color: transparent;
      background: #C9F8CC;
      &:hover {
        background: #5FE77F;
      }
    }
  }
  &.selected {
    padding: 0;
  }
}
.title_indent{
  padding-left: 5px;
  background-color: #fff;
  border-radius: 8px;
  //border: 4px solid transparent;
  &:hover {
    cursor: text;
    border-color: #E6AC190F;
    background: #E6AC190F;
  }
  &:focus-within{
    background: rgba(230, 172, 25, 0.0588235294);
  }
  &.selected {
    border-radius: 12px;
    background: #F8EAC9;
  }

  &:focus {
    border-color: #E6AC190F;
  }
}
.selectedTitle {
  background: #F8EAC9;  
}
.selected_block {
  & .text_container {
    border: 0.6px solid #ffc72c;
    padding: 3px;
  }
  & .empty_element {
    border: 2px solid #ffc72c;
  }
  & .container {
    border-color: #ffc72c;
  }
  & .selected {
    border-color: #ffc72c;
  }
  & .upload_container {
    border: 0.6px solid #ffc72c;
  }
}

.text_element_display {
  font-size: 16px;
  line-height: 24px;
  margin: 8px 4px;
  color: #242633;
}

.pdf_canvas_placeholder {
  width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: #dde2f9;
  & .message {
    color: #596080;
    font-size: 14px;
    height: 100%;
    padding: 10px;
  }
  background: radial-gradient(
      farthest-corner at top,
      #ffffff 0%,
      #ffe9ad 4%,
      #dde2f9 12.25%,
      #dde2f9 31.25%,
      #ffe9ad 50%
    )
    top right/200% 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  animation: colorChange 1s infinite alternate;
  &.no_anim {
    background: #999999;
    color: #242633;
  }
}

@keyframes colorChange {
  to {
    background-position: top left;
  }
}

.page_canvas_element,
.pdf_canvas_element,
.link_canvas_element,
.embed_canvas_element,
.ms_canvas_element,
.slides_canvas_element,
.image_canvas_element,
.other_canvas_element {
  visibility: visible;
  cursor: pointer;
  white-space: initial;
  &:hover .canvas_actions {
    visibility: visible;
  }
  &.blink_it {
    animation: blinking_touch 1s;
    animation-iteration-count: 2;
  }
}

.view_builder_collapsed {
  &:hover .canvas_actions {
    visibility: visible;
    z-index: 1;
    cursor: pointer;
    right: 33px;
    top: 8px;
  }
  &.textElement {
    * {
      pointer-events: none;
    }
  }
}

@keyframes blinking_touch {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #ffe9ad;
  }
  100% {
    background-color: #ffffff;
  }
}

.pdf_canvas_element.hidden {
  visibility: hidden;
}

.pdf_canvas_element {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 40px;
  cursor: pointer;
  &:hover .canvas_actions {
    visibility: visible;
  }
}

.canvas_actions {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  visibility: hidden;

  & .item {
    margin-left: 16px;
    background: #fff;
    box-shadow:
      0 2px 7px rgba(0, 0, 0, 0.04),
      0 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: 40px;
    &:hover {
      background: #dde0f4;
      & svg {
        fill: #dde0f4;
      }
    }
  }
  & .save {
    //display: flex;
    padding: 10px;
    & svg {
      margin-right: 5px;
    }
  }
  & .download {
    padding: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    & svg path {
      stroke: #596080;
    }
  }
}

.pdf_canvas_container {
  position: relative;
}

.image_canvas_element {
  position: relative;
  margin: 40px auto 0;
  height: auto;
  background: #ffe9ad;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
}

.link_canvas_element {
  position: relative;
  margin: 40px auto 0;
  max-width: 700px;
  height: 114px;
  background: white;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
}
.link_canvas_image {
  width: 164px;
}
.link_canvas_type_icon {
  position: absolute;
  bottom: 12px;
  left: 16px;
}
.slides_canvas_element {
  position: relative;
  margin: 40px auto 0;
  max-width: 700px;
  height: 114px;
  background: white;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
}
.slides_canvas_image {
  width: 164px;
  background-color: #f7ebdc;
}
.slides_canvas_type_icon {
  position: absolute;
  bottom: 12px;
  left: 16px;
}
.ms_canvas_element {
  position: relative;
  margin: 40px auto 0;
  max-width: 700px;
  height: 114px;
  background: white;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
}
.ms_canvas_image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 164px;
  background-color: #ffe9ad;
}
.ms_canvas_type_icon {
  position: absolute;
  bottom: 12px;
  left: 16px;
}
.link_canvas_text_section {
  padding: 16px 48px 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.link_canvas_title {
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #242633;
  margin-bottom: 8px;
  @include ellipsis;
  max-width: 470px;
}
.link_canvas_description {
  font-size: 14px;
  line-height: 14px;
  color: #596080;
  margin-bottom: 12px;
  @include ellipsis;
  line-break: anywhere;
  max-width: 470px;
}
.link_canvas_link {
  font-size: 14px;
  color: #a3aacc;
  @include ellipsis;
  max-width: 470px;
}

.text_canvas_element {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 40px auto 0;
  padding: 20px;
  &.collapsed {
    width: 516px;
  }
  * {
    pointer-events: none;
  }
}

.text_canvas_text {
  font-style: normal;
  width: 100%;
}
.text_canvas_line {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6px;
  background-color: #c5ceff;
}

.page_canvas_element {
  //padding: 16px 0;
  background: white;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-top: 40px;
  padding: 64px 16px 92px;
}
.blink_it.blink_it.blink_it {
  animation: blinking_touch 1s;
  animation-iteration-count: 2;
}

@keyframes blinking_touch {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #ffe9ad;
  }
  100% {
    background-color: #ffffff;
  }
}

.page_canvas_container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}
.page_canvas_description {
  font-size: 14px;
  line-height: 21px;
  color: #202124;
  text-wrap: normal;
  word-break: break-all;
  max-width: 730px;
  padding: 8px 0 1px 0;
  margin-bottom: 5px;
  resize: none;
  border: none;
  overflow: auto;
  height: auto;
  font-family: "RobotoFlex", sans-serif;
}

.page_canvas_no_pointer_events {
  //pointer-events: none;
}

.page_canvas_title {
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 44px;
  line-height: 53px;
  color: #242633;
  margin-bottom: 8px;
}

.empty_element_wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  width: calc(100vw - 430px);

}

.empty_element {
  position: relative;
  margin-left: 1px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FFE9ADFF' stroke-width='2' stroke-dasharray='0.5%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  &.notEdit {
    cursor: default;
  }
  &.hoverable:hover .empty_add_icon {
    display: none;
  }
  &.hoverable:hover .empty_add_icon.notEdit {
    display: flex;
  }
  &.hoverable:hover .empty_menu {
    //display: flex;
  }
  &.hoverable:hover .empty_menu.notEdit {
    pointer-events: none;
  }
  &.hoverable:hover .six_dot_wrap {
    visibility: visible;
  }
  &.dragginOver {
    background-color: #ffe9ad;
    & .empty_add_icon {
      background-color: #ffe9ad;
    }
  }
  &.selected {
    border: 2px solid #ffc72c;
  }
}

.empty_add_icon {
  display: flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  background-color: #ffe9ad;
  border-radius: 8px;
}

.empty_menu {
  width: 100%;
  height: 100%;
  background-color: #ffe9ad;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.center_menu {
  //width: 95%;
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: space-around;
}

.empty_menu_item {
  width: auto;
  height: 24px;
  padding: 4px 6.5px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #484343;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  & svg {
    margin-right: 5px;
    path {
      stroke: #484343;
    }
  }
  &:hover {
    background-color: #ffc72c;
  }
}

.six_dot_wrap {
  visibility: hidden;
  position: absolute;
  display: flex;
  left: -85px;
  width: 50px;
  height: 30px;
  &.isCompressView  {
    height: calc(100% + 2px);
  }
  &.on {
    visibility: visible;
  }
  &.just_a_viewer {
    cursor: not-allowed;
  }
}

.six_dot_positioning {
  left: 25px;
  height: 100%;
  position: relative;
  width: 0;
}

.six_dot_icon {
  height: 24px;
  width: 24px;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
  position: absolute;
  left: -15px;
  top: 50%;
  z-index: 2;
  border: 1px solid transparent;
  background-color: transparent;
  transform: translate(0%, -50%);
  &:hover {
    background-color: rgba(126, 122, 133, 0.04);
  }
  &.on {
    border-color: #B7B5BA;
  }
}

.otherOwner {
  display: block;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -90px;
  top: 50%;
  transform: translate(0%, -50%);
  & > div {
    top: -15px;
    right: -15px;
  }
  & > div.upv_card_ava_placeholder {
  }
}

.six_dot_stripe {
  position: relative;
  //height: 100%;
  display: none;
  top: 50%;
  left: -85px;
  flex-direction: column;
  align-items: center;
  background-color: #ffe9ad;
  & .line {
    background-color: #ffc72c;
    width: 2px;
    height: calc(100% + 14px);
  }
  & .plus {
    transform: translateY(-50%);
    padding: 4px 4px;
    width: 24px;
    height: 24px;
    z-index: 1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    &.bottom {
      top: initial;
      bottom: -19px;
    }
    &:hover {
      background: rgba(126, 122, 133, 0.04);
    }
    & svg path {
      stroke: #484343;
    }
    &.plus_viewer {
      visibility: hidden;
    }
  }
}

.other_canvas_element {
  position: relative;
  margin: 40px auto 0;
  max-width: 700px;
  height: 114px;
  background: white;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
}

.other_canvas_image {
}
.other_canvas_type_icon {
  position: absolute;
  bottom: 12px;
  left: 16px;
}
.other_canvas_text_section {
}
.other_canvas_title {
}
.other_canvas_description {
}
.other_canvas_link {
}

.other_canvas_image_placeholder {
  width: 161px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffe9ad;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.noIconPlaceholder {
  width: 28px;
  height: 34px;
}
.slidesIcon {
  height: 34px;
}

.upload_element {
  background: #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 2px;

  &.viewMode {
    margin: 0 auto;
  }
  width: 460px;
  &.hoverable {
    visibility: visible;
  }
  &.hoverable:hover .six_dot_wrap .plus {
    visibility: visible;
  }
  &.hoverable.active .six_dot_wrap .plus {
    visibility: visible;
  }
  &.hoverable:hover .upload_container {
    background-color: #fff;
  }
  &.dragginOver {
    background-color: #ffe9ad;
    & .upload_container {
      background-color: #ffe9ad;
    }
  }
}

.upload_container {
  border: 1px solid #ffe9ad;
  border-radius: 8px;
  display: flex;
  min-height: 90px;
  width: 461px;
  cursor: pointer;
  &.isComputerTab {
    min-height: 0;
    height: 69px;
  }
  &.noEdit.noEdit {
    cursor: default;
    pointer-events: none;
  }
  & .icon_left {
    margin-left: 3px;
    width: 96px;
    min-height: 88px;
    height: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    & svg path {
      stroke: #596080;
    }
  }
  & .main_right {
    width: 290px;
    margin-left: 4px;
    & .slider {
    }
    & .button {
    }
  }
}

.invisible_input {
  display: none;
}

.six_dot_permanent_line {
  display: flex;
  .line {
    width: 2px;
    position: absolute;
    &.regular {
      height: 100%;
    }
    &.first {
      height: 50%;
      transform: translate(0%, 100%);
    }
    &.last {
      height: 50%;
    }
    &.no_line {
      display: none;
    }
  }
}
.checkbox_balancer{
  height: 100%;
  display: flex;
  align-items: center;
  left: -2px;
  position: relative;
  opacity: 0; // Почему это для експандед??
  cursor: default;
}
.six_dot_item_number {
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #ffe9ad;
  border-radius: 6px;
  color: #534b4f;
}

.six_dot_placeholder {
  height: 32px;
  width: 32px;
  left: -15px;
  background: #ffe9ad;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(0%, -50%);
  &.selected {
    background: #ffc72c;
  }
  &.selected .six_dot_item_number {
    color: #0026e5;
  }
  &.isTitle {
    background: #FFBFBF;
  }
  &.isTitle .six_dot_item_number  {
    background: #FFBFBF;
  }
}

.six_dot_balancer.active .six_dot_icon {
  display: flex;
}
.six_dot_dropMenu_wrapper {
  position: absolute;
  top: 50%;
  z-index: 11;
  &.six_dot_dropMenu_wrapper_active{
    z-index: 40;
  }
}

.container {
  position: relative;
  background: #ffffff;
  width: 100%;
  border: 1px solid #ffe9ad;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 96px minmax(100px, 350px) 64px;
  height: 90px;
  overflow: hidden;
  cursor: pointer;

  &.blink_it {
    animation: blinking_touch 1s;
    animation-iteration-count: 3;
  }

  @keyframes blinking_touch {
    0% {
      background-color: #ffffff;
    }
    50% {
      background-color: #ffe9ad;
    }
    100% {
      background-color: #ffffff;
    }
  }
  &:hover .middle,
  &:hover .icon_right {
    background-color: #f0f1fe;
  }

  &.big {
    height: 140px;
  }
  &.selected {
    border-color: #ffc72c;
  }
}

.image_left {
  position: relative;
  overflow: hidden;
}
.middle {
  display: grid;
  grid-template-rows: 50px 1fr;
  &.big {
    grid-template-rows: 87px 1fr;
  }
  & .text {
    padding: 16px;
  }
  & .hint {
    padding-left: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #a3aacc;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    & svg path {
      stroke: #a3aacc;
    }
  }

  .title {
    max-width: 280px;
    @include ellipsis;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    max-height: 40px;
    line-height: 20px;
    color: #242633;
    word-break: break-all;
  }
  .description {
    display: flex;
    @include ellipsis;
    max-width: 90%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #596080;
    &.small {
      display: none;
    }
  }
}
.icon_right {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 88px;
  width: 64px;
  align-self: center;

  & svg.withHover:hover {
    & path {
      stroke: #596080;
    }
  }
}
.can_edit_wrapper {
  position: absolute;
  right: -33px;
  top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter {
  opacity: 0;
  position: absolute;
  right: 0;
  top: -14px;
  font-size: 12px;
  font-family: "RobotoFlex", sans-serif;
}
.isAIGenerated {
  border: 4px solid rgba(25, 230, 39, 0.04);
  background: #C9F8CC;
  &:hover {
    background: #5FE77F;
  }
}

.counter_grey {
  color: #a3aacc;
}

.counter_red {
  color: #c21c44;
}

.counter_yellow {
  color: #ed9716;
}

.counter_visible {
  opacity: 1;
}

.embed_canvas_element {
  height: 450px;
  position: relative;
  margin-bottom: 40px;
}

.embed_preview {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #fff;
  & iframe {
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: 470px;
    padding: 10px;
  }
}

.number_plate {
  height: 32px;
  width: 32px;
  left: -64px;
  background: #ffe9ad;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 16px;
  transform: translate(0%, -50%);
  & .number {
    font-style: normal;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #ffe9ad;
    border-radius: 6px;
    color: #596080;
  }
}

.batch_actions_wrapper {
  position: relative;
  top: 300px;
  left: 20px;
}

.translate {
  height: 30px;
  display: flex;
  align-items: center;
  gap: 5px;

  div {
    clip-path: inset(0 7px 0 0);
    animation: l 1s steps(4) infinite;
  }
}

@keyframes l {
  to {
    clip-path: inset(0 -1.5px 0 0)
  }
}

.translate {
  height: 30px;
  display: flex;
  align-items: center;
  gap: 5px;

  div {
    clip-path: inset(0 7px 0 0);
    animation: l 1s steps(4) infinite;
  }
  
}