.top_wrapper {
  position: absolute;
  &.relative {
    position: relative;
    background-color: inherit;
  }
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    padding-left: 4px;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 120px;
    & .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .container {
    cursor: pointer;

    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #CBCBCB;
    background: #ffeebf;
    -webkit-transition-property: background;
    -moz-transition-property: background;
    -o-transition-property: background;
    transition-property: background;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    .knob {
      position: absolute;
      top: -1px;
      background: #fff;
      border: 1px solid #CBCBCB;
      border-radius: 50%;
      -webkit-transition-property: left;
      -moz-transition-property: left;
      -o-transition-property: left;
      transition-property: left;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
    }
    .knob_left {
    }
    .knob_right {
      background: #ffffff;
      border: 1px solid #ece2e2;
    }
    &.active {
      background: #ffc72c;
      border: 1px solid #ece2e2;
    }
    &.isDisable.isDisable{
      cursor: no-drop;
    }

    &.isAppSettings {
      background: #d7d7d6;
      
      &.active {
        background: #534B4F;
        border: 1px solid #534B4F;
        }
    }
  }
}
.top_wrapper {
  .darkMode {
    background: #cbcbcb;
  }
  .container {
    &.active {
      background-color: #534b4f;
    }
  }
}

