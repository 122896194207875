@import "./src/_mixins";

.modal_wrapper::-webkit-scrollbar {
  display: none;
}
.modal_wrapper {
  position: fixed;
  background: rgba(147, 147, 147, 0.8);
  z-index: 105;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  font-style: normal;
  font-weight: 400;
  &.confirm_close {
    background-color: rgba(36, 38, 51, 0.5);
  }
  & .modal_offset {
    margin-left: 61px;
  }
  .modal {
    margin: auto;
    background: #ffffff;
    width: fit-content;
    border-radius: 6px;
  }
  .modal_tag.modal_tag {
    padding-left: 16px;
    width: 450px;
    border-radius: 12px;
  }
  .downloadPlaylist_placeholder.downloadPlaylist_placeholder {
    height: 400px;
  }
  .downloadPlaylist {
    position: relative;
    width: 450px;
    height: fit-content;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;

    .header {
      z-index: 20;

      .close {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    .image_wrapper {
      width: 100px;
      min-width: 100px;
      height: 100px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.15);
      background: #ffffff;
      position: relative;
      margin: 25px 0 14px;

      .image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
        box-shadow: 0 2px 2px rgba(120, 120, 120, 0.25);
      }

      svg {
        width: 100%;
        height: 100px;
        position: absolute;
      }
    }

    .modal_body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &_details {
        width: 410px;
        height: fit-content;
        border-radius: 8px;
        background: rgba(241, 241, 240, 0.40);
        padding: 16px 3px 13px;
        margin: 14px 0 13px;
        display: flex;
        flex-direction: column;
        gap: 3px;

        &_files_wrapper {
          display: flex;
          align-items: center;

          .chevron_wrapper {
            width: 22px;
            height: 22px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 3px;

            &:hover {
              background: #FFE9AD;
            }

            > svg {
              margin-top: 1px;
              transform: rotate(-90deg);
              width: 8px;
              height: 4px;
            }
            &.is_rotated {
              svg {
                transform: rotate(0);
                margin-top: 2px;
              }
            }
          }
        }

        &_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 13px;
          
          .files_count_wrapper {
            display: flex;
            svg {
              margin-right: 4px;
            }
          }

          p {
              margin: 0;
              color: #939393;
              font-family: RobotoFlex, sans-serif;
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
          }         
        }

        &_table {
          padding-left: 13px;
          max-height: 0;
          height:0;
          transition: all 0.2s;
          overflow: hidden;

          &.expanded_menu {
            padding-top: 10px;
            height: fit-content;
            max-height: 265px;
            overflow: visible;
          }

          &_header {
            display: flex;
            align-items: center;
            color: #939393;
            font-family: RobotoFlex, sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            position: sticky;
            top: 0;

            .select_all_wrapper {
              border-radius: 4px;
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;

              &:hover {
                background: #FFEEBF;
              }

              svg {
                min-width: 16px;
                width: 16px;
                height: 16px;
              }
            }

            
            
            .table_title {
              margin: 0 0 0 12px;
            }
          }

          &_body {
            height: fit-content;
            max-height: 0;
            overflow: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;

            &::-webkit-scrollbar {
              width: 4px;
              background-color: #ffffff;
            }
          
            &::-webkit-scrollbar-thumb {
              display: block;
              border-radius: 4px;
              background: #ECE2E2;
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            }
          
            &::-webkit-scrollbar-track-piece {
              display: none;
              background-color: #ffffff;
            }
          
            &::-webkit-scrollbar-track {
              border-radius: 6px;
            }
            .file_row {
              display: flex;
              padding: 4px;
              cursor: pointer;
              border-radius: 4px;
              width: 378px;
              height: 23.75px;

              &.disabled {
                opacity: 0.2;
                cursor: not-allowed;

                &:hover {
                  background: transparent;
                }
              }

              &:hover {
                background: #FFEEBF;
              }

              svg {
                margin-right: 12px;
              }

              span {
                width: 16px;
                text-align: right;
              }

              .file_title {
                margin: 0;
                margin-left: 16px;
                color: #585858;
                font-family: RobotoFlex, sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        
          &.expanded_menu {
            .modal_body_details_table_body {
              max-height: 235px;
              transition: all 0.3s;
            }
          }
        }
      }

      .title {
        color: #343434;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 600;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        white-space: nowrap;
      }

      .add_link_checkbox {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        gap: 6px;
        cursor: pointer;
        
        p {
          color: rgba(29, 29, 29, 0.35);
          font-family: RobotoFlex, sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          margin: 0;
        }

        &:hover {
          p{
            color: #1D1D1DB2;
          }
          svg path{
            stroke: #1D1D1DB2;
          }
        }

      }
    }
    .controls_wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 12px;

      .download_button,
      .cancel_button {
        height: 32px;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 6px;
        font-family: RobotoFlex, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.7px;
      }

      .cancel_button {
        width: 80px;
        color: #484343;
        background-color: #fff;;
      }

      .download_button {
        background: #1FAE09;
        width: 103px;
        box-shadow: 0 1px 4px 0 rgba(122, 105, 105, 0.10);
        color: #fff;

        &:hover {
          background-color: #41C82C;
          border-color: #41C82C;
        }
        &.disabled {
          opacity: 0.2;
          cursor: not-allowed;

          &:hover {
            background-color: #1FAE09;
          }
        }
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background: rgba(29, 29, 29, 0.08);
      margin: 13px 0 21px;

      &.first {
        margin: 2px 0 7px;
      }
    }
  }
  .complementary_playlist {
    width: 617px;
    height: 740px;
    & .title {
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      line-height: 24px;
      color: #242633;
      margin: 29px 41px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .close {
        border-radius: 6px;
        cursor: pointer;
        &:hover {
          background: #e8ebfa;
        }
      }
    }
    .search {
      width: 537px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #d7ddf7;
      box-sizing: border-box;
      border-radius: 6px;
      margin-left: 40px;
      //margin-top: 8px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      margin-top: 32px;
      & .search_icon {
        margin: 0 6px;
      }
      & input {
        border: 0;
        font-size: 14px;
        width: 437px;
        line-height: 21px;
        color: #242633;
        &::placeholder {
          color: #a3aacc;
        }
      }
    }
    .wrapper {
      height: 550px;
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: 8px;
        background-color: #e2e1e1;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.13);
      }
      &::-webkit-scrollbar-track-piece {
        display: none;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 11px);
        height: 91%;
        flex-direction: column;
        margin-left: 11px;
        margin-top: 0;
        .empty_head {
          font-size: 12px;
          text-transform: uppercase;
          color: #242633;
          margin-top: 10px;
        }
        .empty_desc {
          font-size: 12px;
          line-height: 18px;
          color: #596080;
          margin-top: 7px;
        }
      }
      .playlists_wrapper {
        cursor: pointer;
        width: 537px;
        height: 121px;
        border: 1px solid #d7ddf7;
        border-radius: 8px;
        display: flex;
        margin-left: 40px;
        margin-top: 8px;
        position: relative;
        .checkbox {
          position: absolute;
          left: 8px;
          top: 8px;
          z-index: 10;
        }
        .checkbox_off {
          visibility: hidden;
        }
        &:hover .checkbox_off {
          visibility: visible;
        }
        &:hover {
          border: 1px solid #a3aacc;
        }
        &.selected {
          border: 1px solid #0026e5;
        }
        .image_wrapper {
          position: relative;
          width: 121px;
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
          height: 119px;
          overflow: hidden;
          .image {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 121px;
            height: 121px;
            object-fit: cover;
            overflow-x: hidden;
          }
          .image_crop {
            position: absolute;
            left: -50%;
            /*top: 50%;*/
            /*transform: translate(-50%, -50%);*/
            width: 330px;
            /*max-height: 124px;*/
            object-fit: cover;
            overflow-x: hidden;
          }
        }
        .info {
          margin-left: 18px;
          margin-top: 19px;
          .playlist_title {
            font-weight: 600;
            font-family: "Roboto", sans-serif;
            font-size: 18px;
            color: #242633;
            width: 381px;
          }
          .description {
            height: 21px;
            font-size: 14px;
            color: #596080;
            width: 381px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 8px;
          }
        }
        .about {
          display: flex;
          margin-top: 11px;
          .author {
            font-size: 12px;
            color: #596080;
            display: flex;
            align-items: center;
            width: 133px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            & img {
              margin-right: 9px;
              width: 24px;
              height: 24px;
            }
          }
          .time {
            padding-left: 10px;
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            font-size: 14px;
            color: #a3aacc;
            img {
              margin-right: 9px;
            }
          }
        }
      }
    }
    .option {
      height: 72px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding-right: 40px;
      .cancel {
        font-size: 12px;
        line-height: 12px;
        color: #596080;
        width: 89px;
        height: 32px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 6px;

        &:hover {
          background: #e8ebfa;
        }
      }
      .add {
        width: 89px;
        height: 32px;
        border: 1px solid #0026e5;
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #0026e5;
        cursor: pointer;
        &:hover {
          background: #e8ebfa;
        }
      }
    }
  }
  .AddImageToPlaylist {
    width: 590px;
    height: 700px;
    border-radius: 12px;
    & .title {
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 12px;
      margin: 16px;
      letter-spacing: 0.05em;
      color: #d3c8c8;
      // .close{
      //   border-radius: 4px;
      //   cursor: pointer;
      //   width: 20px;
      //   height: 20px;
      //   margin-top: 6px;
      //   margin-right: 4px;
      //   &:hover {
      //     background: #E8EBFA;
      //   }
      // }
    }
    & .search {
      width: 276px;
      margin-left: 16px;
    }
    & .custom_scroll {
      overflow-y: auto;
      overflow-x: hidden;

      &_hidden-scroll {
        overflow-y: hidden;
        overflow-x: hidden;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: 4px;
        background-color: #e2e1e1;
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.13);
      }
      &::-webkit-scrollbar-track-piece {
        display: none;
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
      }
    }
    &_content_image {
      display: flex;
      flex-wrap: wrap;
      overflow: scroll;
      align-content: flex-start;
      column-gap: 20px;
      row-gap: 10px;

      &_all {
        height: 592px;
      }
      &_folder {
        height: 548px;
        margin: 0;
      }

      .component {
        width: 170px;
        position: relative;
        z-index: auto;
        height: 201px;
        top: 1px;
        animation: fadein_card_show 0.5s;
        -moz-animation: fadein_card_show 0.5s; /* Firefox */
        -webkit-animation: fadein_card_show 0.5s; /* Safari and Chrome */
        -o-animation: fadein_card_show 0.5s;
        cursor: pointer;
        .image_preview {
          position: relative;
          height: 170px;
          border-radius: 8px;

          background: #ffffff;
          box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.1);
        }
        .image_overlay {
          position: absolute;
          z-index: 1;
          left: -1px;
          top: -1px;
          max-height: 172px;
          border-radius: 8px;
          visibility: visible;
        }
        &.hidden {
          display: none;
        }
        &.pending div {
          display: none;
        }

        &.pending {
          background-color: #e8ebfa;
          animation: downLoading 1s infinite ease-in-out;
        }

        .image_preview img {
          width: 170px;
          height: 170px;
          object-fit: cover;
          border-radius: 8px;
          border: 0.6px solid #ece2e2;
        }
        .itemMenu {
          position: relative;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: auto;
          width: 22px;
          height: 22px;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
        }

        .itemMenu svg path {
          stroke: #596080;
        }

        .itemMenu:hover {
          background-color: #e8ebfa;
        }

        .folderBuffer {
          position: absolute;
          top: 106px;
          right: 1px;
          width: 24px;
          height: 27px;
          padding: 0;
          margin: 6px;
          z-index: 7;
          align-self: flex-end;
        }

        .folderIcon {
          position: relative;
          padding: 4px;
        }

        .folderIcon svg {
          width: 100%;
          height: 100%;
        }

        .image_title {
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          color: #242633;
          white-space: nowrap;
          text-align: center;
        }

        &:hover .image_overlay {
          visibility: visible;
          background-color: #ffeebf;
          opacity: 0.7;
          border: 2px solid #ffc72c;
          box-shadow: 1px 1px 10px rgba(122, 105, 105, 0.15);
          width: calc(100% + 2px);
          height: calc(100% + 2px);
        }
      }
    }
    & .collections {
      //margin-top: 10px;
      height: 580px;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-left: 15px;

      & .collection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px;
        height: 33px;
        cursor: pointer;
        padding: 10px;
        color: #242633;

        & > div {
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        & .collection_text {
          white-space: nowrap;
          width: 360px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        & svg {
          height: 24px;
          width: 24px;
          padding: 2px;
          margin-right: 7px;
          & path {
            stroke: #596080;
          }
        }
      }
      & .in_folder {
        & .folder {
          margin-left: 14px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          height: 30px;
          cursor: pointer;
          width: 400px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          & svg {
            margin-right: 10px;
          }
        }
      }
    }
  }


  .PublishPlaylistModal {
    width: 399px;
    border: 0.6px solid #ece2e2;
    box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);
    border-radius: 16px;
    .scrollable_parent {
      max-height: 633px;
      & .input_wrapper .input_group_description {
        font-size: 15px;
        line-height: 18px;
        color: #939393;
      }
      & .availability {
        margin-top: 16px;
        .item_title {
          font-size: 15px;
          line-height: 18px;
          color: #939393;
        }
      }
      &::-webkit-scrollbar {
        width: 8px;
        background-color: #fafaff;
      }
      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: 8px;
        background-color: #e2e1e1;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      }
      &::-webkit-scrollbar-track-piece {
        display: none;
        background-color: #fafaff;
      }
    }
    .content_wrapper {
      position: relative;
      padding: 20px;
      user-select: none;
      .header {
        margin-bottom: 19px;
        .main_label {
          font-weight: 600;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.03em;
          color: #343434;
        }
      }
      .controls_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;
        button {
          font-family: "RobotoFlex", sans-serif;
          margin-left: 18px;
        }
      }
    }
  }

  &_conten_folder {
    margin-left: 15px;
  }
  .sideBarLib__header_navigation {
    margin-left: 18px;
    margin-top: 4px;
  }
  .DeleteImageToPlaylist {
    height: 160px;
    width: 356px;
    border-radius: 8px;
    padding: 20px;

    & .title {
      font-family: "RobotoFlex", sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: #242633;
    }
    & .description {
      padding-top: 8px;
      font-size: 12px;
      line-height: 18px;
      color: #242633;
    }
  }
  .group_buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 42px;
    &_cancel {
      font-family: "RobotoFlex", sans-serif;
      width: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #484343;
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.7px;
      text-transform: uppercase;
      padding: 10px 14px;
      margin-right: 20px;
    }
    &_delete {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 118px;
      height: 32px;
      border-radius: 6px;
      background: #1FAE09;
      border: 0.6px solid #1FAE09;
      box-shadow: 0 1px 4px 0 rgba(122, 105, 105, 0.10);
      text-transform: uppercase;
      cursor: pointer;
      color: #FFF;
      font-family: RobotoFlex, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.7px;

      &:hover {
        background: #41C82C;
        border-color: #41C82C;
      }
    }
  }
  .LeaveChannel {
    width: 356px;
    height: 130px;
    padding: 20px;
    & .title {
      font-family: "Roboto", sans-serif;
      color: #D3C8C8;
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    & .text {
      color: #484343;
      font-size: 16px;
      font-weight: 500;
    }
    & .group_buttons {
      display: flex;
      justify-content: flex-end;
      gap: 24px;
      padding-top: 16px;
    }
    & .group_buttons_cancel {
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      line-height: 12px;
      text-transform: uppercase;
      color: #484343;        
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.7px;
      margin-right: 0;
    }
  }

  .InviteUserModal,
  .AddMemberModal,
  .EditContact,
  .CheckoutModal {
    width: 480px;
    padding: 24px;
    & .group_buttons {
      margin-left: 250px;
      & .group_buttons_send_invite {
        font-family: "RobotoFlex", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 116px;
        height: 32px;
        border-radius: 6px;
        background: #1FAE09;
        border: 0.6px solid #1FAE09;
        box-shadow: 0 1px 4px 0 rgba(122, 105, 105, 0.10);
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
          background: #41C82C;
          border-color: #41C82C;
        }
        &.disabled {
          opacity: 0.2;
          cursor: not-allowed;
          &:hover {
            background: #1FAE09;
          }
        }
      }
    }

    & .title_wrapper {
      font-family: "RobotoFlex", sans-serif;
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #242633;
      & .title {
        padding-top: 2px;
      }
    }
  }
  .AddToSFModal {
    width: 480px;
    max-height: 600px;
    height: fit-content;
    min-height: 540px;
    padding: 16px;

    & .title_wrapper {
      font-family: "RobotoFlex", sans-serif;
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #232225;
      & .title {
        padding-top: 2px;
      }
    }
    .input_search {
      margin: 14px 0;
      display: flex;
      color: #8D8A93;
      width: 99%;
      height: 40px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      border: 1.5px solid #A2A0A7;
    }
    .items_list_wrapper {
      overflow-y: scroll;
      max-height: 420px;
      height: fit-content;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        position: absolute;
      }
    
      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: 4px;
        background-color: #dbdbdb;
        -webkit-box-shadow: inset 0 0 6px #dbdbdb;
        margin-right: 2px;
      }
    
      &::-webkit-scrollbar-track {
        border-radius: 5px;
        position: absolute;
      }
    
      &::-webkit-scrollbar-track-piece {
        display: none;
        background: #dbdbdb;
      }
    }
    .items_section {
      margin-left: 16px;
      align-items: flex-start;
      color: var(--Sheme_fg_subtle);
      font-family: "RobotoFlex", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    .item {
      display: flex;
      padding: 4px 16px;
      height: 32px;
      align-items: flex-start;
      color: #514E55;
      font-family: "RobotoFlex", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;
      &:hover {
        border-radius: 8px;
        background: rgba(230, 172, 25, 0.06);
        color: #E7C15F;
      }
    }
    
    .empty_state {
      display: flex;
      flex-direction: column;
      height: 418px;
      align-items: center;
      justify-content: center;
      p {
        font-family: RobotoFlex, sans-serif;
        font-weight: 400;
        color:  #8D8A93;
        margin: 12px 0 4px;
        font-size: 12px;
        font-style: normal;
        line-height: 15px;

        &:last-child {
          margin: 0;
          color: #514E55;
          font-size: 14px;
          line-height: 17.5px;
        }
      }
    }
  }
  .AddMemberModal {
    padding: 20px;
    & .title_wrapper {
      margin-bottom: 8px;
      font-family: "RobotoFlex", sans-serif;

      .title {
        color: #D3C8C8;
        font-size: 12px;
        font-weight: 600;
        font-family: "Roboto", sans-serif;
        line-height: 12px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
    }
    & .input_area {
      min-height: 32px;
    }

    & .group_buttons {
      font-family: "RobotoFlex", sans-serif;
      padding-top: 0;
      margin-left: 242px;
    }
    & .group_buttons_cancel {
      font-family: "RobotoFlex", sans-serif;
      margin-right: 24px;
      line-height: 12px;
    }

    & .inputEmail_wrapper {
      .input_area {
        padding: 2px 2px 2px 6px;
        border: 1px solid #7a696980;
        
        & input {
          width: 96%;

          &::placeholder {
            color: rgba(122, 105, 105, 0.50);
            font-family: RobotoFlex, sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
          }
        }
        .emailToAdd_wrapper {
          margin: 2px 4px;
          .email {
            margin-left: 3px;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &:has(input:focus) {
          border: 1px solid #ffc72c;
        }
      }
    }
  }

  .CropUserAvatar {
    min-width: 330px;
    max-width: 620px;
    background: #ffffff;
    box-shadow:
      0 1px 4px rgba(0, 0, 0, 0.25),
      0 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 16px;

    .title {
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      margin-bottom: 12px;
      text-transform: uppercase;
      color: #d3c8c8;
    }

    .cropper_container {
      width: 100%;
    }

    .button_group {
      font-family: "RobotoFlex", sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .Summarize {
    min-width: 330px;
    max-width: 620px;
    background: #ffffff;
    box-shadow:
      0 1px 4px rgba(0, 0, 0, 0.25),
      0 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .modal_body_details_row {
      padding: 5px;
      width: 100%;
    }
    .title {
        font-weight: 600;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        margin-bottom: 12px;
        text-transform: uppercase;
        color: #484343;
        text-align: center;
        width: 100%;
    }
    .summary_title {
      color: #A2A5AA;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: -0.05px;
      text-transform: uppercase;
      padding-bottom: 15px;
    }
    .item_name {
      color: #2B2C2E;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.25px;
      margin: 5px 0 14px;
      width: 100%;
    }
    .spinner_wrapper {
      position: relative;
      top: 60px;
    }
    .embed_input_wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      border: 0.6px solid rgb(236, 226, 226);
      box-sizing: border-box;
      border-radius: 6px;
      padding-left: 5px;
      background: rgba(248, 248, 248, 0.84);

      .embed_input {
        width: 332px;
        height: 21px;
        border: none;
        font-family: "RobotoFlex", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        background: rgba(248, 248, 248, 0.84);

        &::placeholder {
          color: #C5C5C5;
        }
      }
    }
    .modal_body_details_table_header {
      .table_title {
        width: 180px;
      }
    }
    .modal_body_details_table_body .file_row{
      .file_title.is_cut {
          max-width: 157px;
          width: 157px;
        }
    }
    .items_wrapper {
      display: flex;
      flex-direction: column;
    }
    .buttons_group {
      display: flex;
      align-self: flex-end;
      gap: 20px;
      margin-top: 10px;
    }
    .break_text {
      max-height: 350px;
      overflow-y: scroll;
      b{
        font-weight: 600;
        font-family: "Roboto", sans-serif;
      }
      p{
        margin: 15px 0;
        line-height: 15px;
      }
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        position: absolute;
      }
      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: 4px;
        background-color: #dbdbdb;
        -webkit-box-shadow: inset 0 0 6px #dbdbdb;
        margin-right: 2px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 5px;
        position: absolute;
      }
      &::-webkit-scrollbar-track-piece {
        display: none;
        background: #dbdbdb;
      }
    }
  }

  .AI_Chat {
    width: 800px;
    .title {
      text-align: left;
    }
    .premade_button {
      width: fit-content;
      height: 20px;
      background: rgb(255, 255, 255);
      border: 0.6px solid rgb(236, 226, 226);
      cursor: pointer;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 6px;
      white-space: nowrap;
      padding: 5px;
      margin: 0;
    }
    .buttons_wrapper {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      .submit_button {
        width: max-content;
        height: 32px;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.05em;
        cursor: pointer;
        box-shadow: rgba(122, 105, 105, 0.1) 0 1px 4px 0;
        border-radius: 6px;
        text-transform: uppercase;
        color: rgb(255, 255, 255);
        background: linear-gradient(317deg, rgb(31, 174, 9) -2.91%, rgb(91, 218, 72) 138.5%);
        border: none;
        padding: 0 10px;
        margin: 0;
      }
    }
    .buttons_group {
      align-items: center;
      width: 100%;
    }
    .split_wrapper {
      display: flex;
      height: 100%;
      .split_left {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 5px;
      }
      .split_right {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 10px;
        .chat_wrapper {
          border: 0.6px solid rgb(236, 226, 226);
          padding: 5px;
          width: 100%;
          height: 110px;
          margin: 10px 0px;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  .ApplicationSettings {
    width: 1207px;
    height: 100vh;
    max-height: 705px;
    background: #ffffff;
    box-shadow:
      0 1px 4px rgba(0, 0, 0, 0.25),
      0 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
}

.search_wrapper {
  position: absolute;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-height: 49px;
  max-height: 265px;
  height: auto;
  width: 359px;
  z-index: 1;
  & .item_wrapper {
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 16px;
    cursor: pointer;
    height: 32px;
    &.active {
      background-color: #ffeebf;
    }
      &:hover {
      background-color: #ffeebf;
    }
  }
  & .avatar {
    margin-right: 7px;
    display: flex;
    align-items: center;
  }
  & .name {
    font-size: 14px;
    line-height: 21px;
    color: #242633;
    @include ellipsis();
    width: 90%;
  }
  & .name_without_ellipsis {
    font-size: 14px;
    line-height: 21px;
    color: #242633;
    margin-right: 3px;
  }
}
.inputEmail_wrapper {
  padding-top: 6px;
  & .inputEmail_description {
    font-size: 15px;
    line-height: 18px;
    color: #939393 !important;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
  }
  & .input_area {
    background: #ffffff;
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 6px;
    height: fit-content;
    display: flex;
    width: 432px; // why ??
    font-size: 14px;
    line-height: 21px;
    color: #242633;
    margin-top: 4px;
    padding: 2px;
    flex-flow: row wrap;
    & .disabled_input {
      height: 23px;
      color: lightgrey;
      cursor: default;
    }

    & input {
      border: none;
      font-size: 14px;
      line-height: 21px;
      color: #242633;
      width: 100%;
    }
    & .emailToInvite_wrapper,
    & .emailToAdd_wrapper {
      width: fit-content;
      height: 24px;
      background-color: #ffeebf;
      border: 1px solid #d7ddf7;
      box-sizing: border-box;
      border-radius: 24px;
      font-size: 14px;
      line-height: 21px;
      color: #242633;
      display: flex;
      &.pending {
        border: 1px solid #9bacf8;
        overflow: hidden;
      }
      &.success {
        border: 1px solid #b2f89b;
        overflow: hidden;
      }
      &.failed {
        border: 1px solid #f89bc5;
        overflow: hidden;
      }
      & .avatar {
        position: relative;
        left: 1px;
      }
      & .email {
        color: #484343;
        margin-left: 6px;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & .close_wrapper {
        width: 22px;
        height: 22px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .sharedModal {
    width: 100%;
    margin-top: 9px;
    min-height: 32px;
    margin-bottom: 12px;
  }
  .darkMode {
    border: 1px solid #ece2e2;
    & input {
      &::placeholder {
        color: rgba(122, 105, 105, 0.5);
      }
    }
  }
  & .input_area:focus {
    border: 1px solid #8697f0;
  }
}
.inputEmail_wrapper:focus-within {
  & .inputEmail_description {
    color: #8697f0;
  }
}

.modal_wrapper .EditContact {
  .inputEmail_wrapper .input_area input {
    width: 100%;
  }
}
.scroll_search_container {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 248px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.13);
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
}
.send_email_invite {
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-top: 9px;
  height: 32px;
  cursor: pointer;
  &:hover {
    background-color: #ffeebf;
    .icon svg path {
      stroke: #242633;
    }
  }
  & .icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
  & .name {
    color: #242633;
    font-size: 14px;
    margin-right: 4px;
    white-space: nowrap;
  }
  & .quote_email {
    color: #0000a8;
    max-width: 208px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .quotes {
    color: #242633;
  }
}
.DeleteTag.DeleteTag {
  width: 500px;
  padding: 20px;
  border-radius: 8px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  & .group_buttons {
    padding-top: 13px;
    display: flex;
    gap: 24px;
    justify-content: flex-end;

    & .group_buttons_cancel {
      margin-right: 0;
      color: #484343;
      font-size: 14px;
    }

    & .group_buttons_delete.tags,
    & .group_buttons_cancel.tags {
      width: 73px;
      padding: 0;
    }
  }

  & .title {
    font-family: "RobotoFlex", sans-serif;
    margin-bottom: 8px;
    color: #484343;     
    font-size: 16px;
    font-weight: 500;
  }

  & .sub_title {
    font-style: normal;
    font-weight: normal;
    color: #484343;
    font-size: 14px;
    line-height: 17px;
  }
}
.addPlaylistToPlaylistWindow.addPlaylistToPlaylistWindow {
  width: 322px;
  padding: 25px 16px 16px 17px;
  border-radius: 8px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);

  & .group_buttons_three {
    display: flex;
    justify-content: space-between;
    padding-top: 34px;
    & .group_buttons {
      margin-left: 0;
      padding-top: 0;
    }
    & .group_buttons_cancel {
      margin-right: 25px;
      border: 1px solid #d7ddf7;
      border-radius: 6px;
      color: #8e8f93;
    }
    & .group_buttons_add {
      margin-right: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 12px;
    }
    & .group_buttons_delete,
    & .group_buttons_add,
    & .group_buttons_cancel {
      width: 62px;
      padding: 0;
      margin-right: 0;
    }
  }

  & .title {
    font-family: "RobotoFlex", sans-serif;
    margin-bottom: 8px;
  }

  & .sub_title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #596080;
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 510px;
  flex-direction: column;
  width: 100%;
  &_header {
    font-size: 12px;
    color: #242633;
    text-transform: uppercase;
  }
  &_description {
    font-size: 12px;
    color: #596080;
    text-align: center;
    max-width: 340px;
    line-height: 18px;
    margin-top: 8px;
  }
}
.loader_wrapper {
  position: absolute;
  width: 60px;
  height: 60px;
}

@keyframes downLoading {
  from {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  to {
    opacity: 0.2;
  }
}

.item_wrapper {
  margin: 24px 11px 0 0;
  &.references {
    margin-top: 22px;
  }
  &.availability {
    margin-top: 24px;
    z-index: 2;
  }
  &.channel {
    margin-top: 0;
    padding-left: 1px;
  }
  &.channel2023 {
    margin: 0;
  }
  &.hashtags {
    margin: 14px 11px 0 0;
  }
  &.tags {
    margin-top: 22px;
  }
  &.comments {
    margin-top: 24px;
  }
  &.remix {
    margin-top: 6px;
    padding-bottom: 20px;
  }
  position: relative;
  &:focus-within .item_title.hashtags {
    color: #8697f0;
  }
  .item_title_container {
    width: 100%;
    margin: 0 22px 0 0;
    display: flex;
    align-items: center;
    &.toggler {
      margin: 0;
    }
  }
  .item_title {
    margin: 0 0 8px 0;
    &.availability {
      margin-bottom: 8px;
    }
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #242633;
    &.noDescription.noDescription {
      margin-bottom: 8px;
    }
    & span.hint {
      font-family: "RobotoFlex", sans-serif;
      padding-left: 2px;
      color: #b30e00;
    }
    &.toggler {
      margin: 0 0 8px 0;
    }
    //&.hashtags{
    //    color: #8697F0;
    //}
    &.hashtags {
      margin-bottom: 4px;
    }
  }
  .item_subtitle {
    height: 36px;
    width: 315px;
    left: 0;
    right: 64px;
    top: 24px;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #596080;
  }
  .item_description {
    &.hashtags {
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #596080;
      margin-bottom: 8px;
    }
  }
  .item_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d7ddf7;
    border-radius: 6px;
    height: 32px;
    &.text {
      max-width: 232px;
    }
    &.hashtags {
      max-width: 100%;
      height: auto;
      width: 451px;
      overflow: hidden;
      &:focus-within {
        border: 1px solid #8697f0;
      }
    }
    &.datepicker {
      max-width: 100%;
      height: auto;
      border: none;
    }
    & .input_text_wrapper {
      width: 232px;
      height: 30px;
      padding: 2px 4px;
      & input {
        width: 100%;
        height: 100%;
        border: none;
        font-family: "RobotoFlex", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;

        &::placeholder {
          font-family: "RobotoFlex", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #a3aacc;
        }
      }
    }
    & .input_datepicker_wrapper {
      border: none;
      width: 100%;
      .picker_controls_wrapper {
        height: 32px;
        width: 232px;
        border: 1px solid #d7ddf7;
        border-radius: 6px;
        background: #f5f6ff;
        margin: 0 0 12px 0;
        .controls_container {
          padding: 2px;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .controls_item {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 72px;
            height: 24px;
            border-radius: 6px;
            font-family: "RobotoFlex", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #596080;
            &.active {
              background: #8697f0;
              color: #ffffff;
            }
          }
        }
      }
      .picker_input_wrapper {
        padding-left: 2px;
        margin-bottom: 10px;
        & .material {
          outline: 2px solid #b30e00;
        }
        & .wojtekmaj {
          outline: 2px solid darkgreen;
        }
        & .flatpicker {
          outline: 2px solid silver;
        }
        & .hackerone {
          outline: 2px solid turquoise;
        }
        & .item_container {
          & .input_text_wrapper {
          }

          & .controls {
            display: flex;
            justify-content: center;
            align-items: center;
            .button_wrapper {
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              border: 1px solid transparent;
              background: white;
              cursor: pointer;

              &:hover,
              &.active {
                background: #e8ebfa;
              }
            }
          }
        }
      }
    }
    .controls {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 4px;
      .button_wrapper {
        width: 20px;
        height: 20px;
        cursor: pointer;
        border-radius: 4px;
        &:hover,
        &.active {
          background: #e8ebfa;
        }
      }
    }
  }
}
.addTagModal.addTagModal {
  background-color: rgba(147, 147, 147, 0.6);
}
.addTagModal,
.EmbedPageModal {
  .modal {
    min-width: 436px;
    padding-top: 12px;
    padding-left: 12px;
  }
  .group_buttons {
    background-color: white;
    justify-content: flex-end;
    margin: 12px 12px;
    padding-top: 12px;
    .group_buttons_apply {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #596080;
      border-radius: 6px;
      padding: 0 12px;
      cursor: pointer;
      &:hover {
        background: #e8ebfa;
      }
    }
    .group_buttons_cancel {
      height: 32px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #596080;
      border-radius: 6px;
      white-space: nowrap;
      width: fit-content;
    }
    .text {
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      color: #242633;
      width: 381px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .title {
    margin-left: 8px;
    margin-top: 8px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #242633;
    //margin: 29px 41px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close {
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        background: #e8ebfa;
      }
    }
  }
  .description {
    margin-left: 8px;
    height: 21px;
    font-size: 14px;
    color: #596080;
    width: 381px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 8px;
  }
  .iframe_input {
    margin-left: 8px;
    margin-top: 8px;
    margin-right: 18px;
    padding: 8px 4px;
    border-radius: 6px;
    border: 1px solid #e8ebfa;
    font-weight: 400;
    font-family: Monospaced, sans-serif;
    font-size: 12px;
    width: 398px;
    &.active {
      border: 1px solid #d7ddf7;
    }
  }
}

.inputEmail_description.required:after {
  content: "*";
  color: #c21c44;
  margin-left: 2px;
}

.text_wrapper_w_ellipsis {
  @include ellipsis();
  width: 90%;
}
.avatar{
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider{
  width: 340px;
  height: 1px;
  margin: 17px 0 18px 0;
  background: rgba(29, 29, 29, 0.08);
}

.answer{
  height: auto;
  margin: 0 0 18px 0;
  padding: 10px;
  background: rgba(29, 29, 29, 0.08);
  border-radius: 6px;
  word-break: break-all;
  border: 1px solid #ECE2E2;
}

.CheckoutModal {
  height: 600px;
}

.CheckoutModalElementWrapper {
  width: 100%;
  height: 100%;
}